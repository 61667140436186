.posts-container {
  display: block;
  margin: auto;
  width: 80%;
  align-items: center;
}

.fab {
  position: fixed !important;
  bottom: 5%;
  right: 10%;
  z-index: 99 !important;

  border-radius: 50% !important;
  width: 50px !important;
  height: 50px !important;
}
.fab .icon {
  position: absolute;
  top: -17px;
}

.input-error-msg {
  margin: auto;
  width: 80%;
}

.ep-rating {
  display: flex !important;
  justify-content: space-between !important;
}
.rating-btn {
  display: flex !important;
  align-items: flex-end !important;
}
.ep-btn {
  display: flex !important;
}

.search-result {
  display: flex;
  height: 45px !important;
  padding: 5px !important;
  margin-bottom: 0px;
}
.search-text h4 {
  margin-top: -5px !important;
  margin-bottom: 0 !important;
}
.search-text p {
  margin-top: 0px !important;
  color: #666666 !important;
  margin-bottom: 0 !important;
}
.search-img {
  width: 40px !important;
  object-fit: cover;
  margin-right: 10px !important;
  border-radius: 5px !important;
}

.user-info-container {
  display: block;
  margin: auto;
  width: 80%;
  align-items: center;
}

.avatar-input-box {
  display: flex;
}
.change-avatar-btn {
  margin-left: 5px !important;
  height: 36px !important;
}
