.single-post-container {
  display: block;
  margin: auto;
  width: 80%;
  align-items: center;
}

.back-btn {
  position: fixed !important;
  bottom: 5%;
  right: 10%;
  z-index: 99 !important;
}

.review-box {
  display: block;
  width: 80%;
  margin: auto;
  align-items: center;
  margin-top: 40px;
}
/* GEIST UI STUFF */
.review-text .content.jsx-2956695818 {
  margin: 0pt;
  padding: 0pt !important;
  position: relative;
  text-align: left;
}
.review-text {
  display: flex;
  align-items: flex-start;
}
.review-rating {
  display: flex;
  justify-content: center !important;
  font-weight: 400 !important;
  border-radius: 5px;
  width: 5.625rem;
  height: 4.388rem;
  border: 3px solid;
  border-color: #a5a5a5;
  font-size: 3rem;
  margin-right: 5px;
}
.review-rating.good {
  border-color: #00ab66;
}
.review-rating.bad {
  border-color: #ee0000;
}
.avatar-text {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  padding: 0 22px 0 22px;
}
.body {
  padding: 0 22px 0 22px;
}
.username-ep {
  margin-left: 10px;
  margin-bottom: 0;
}
.username-ep h2 {
  margin-bottom: -12px;
}
.username-ep h4 {
  margin-bottom: 0;
  color: #666666;
}
.user-avatar {
  margin-top: 0;
  border-radius: 5px;
  width: 50px !important;
  height: 50px;
}
.review-footer {
  display: flex;
  justify-content: space-between;
}
.review-footer p {
  color: #666666;
}
.like-delete-btn {
  display: flex;
}

.comment-container {
  margin-top: 20px;
  flex-direction: column;
  align-items: flex-end;
  flex-shrink: 0;
}
.create-comment {
  display: flex;
  flex-grow: 1;
}
.submit-btn {
  margin-left: 10px;
  margin-right: 5px;
  height: auto !important;
}

.user-comment {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.comment-avatar {
  width: 200px !important;
}
.user-comment-content {
  padding-left: 15px;
  padding-right: 22px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  border-left: 1px solid;
  border-color: #eaeaea;
}

@media only screen and (max-width: 780px) {
  .single-post-container {
    display: block;
    margin: auto;
    width: 90%;
    align-items: center;
  }

  .single-post-container p {
    font-size: 12px;
  }

  .review-box {
    display: block;
    width: 100%;
    margin: auto;
    align-items: center;
    margin-top: 40px;
  }
  .review-box h1 {
    font-size: 1.2rem !important;
  }
  .review-box h2 {
    font-size: 14px !important;
  }
  .review-box h4 {
    font-size: 12px !important;
  }
  .user-avatar {
    margin-top: 0;
    border-radius: 5px;
    width: 40px !important;
    height: 40px;
  }
  .username-ep {
    margin-left: 10px;
    margin-bottom: 0;
  }
  .username-ep h2 {
    margin-bottom: auto;
  }
  .username-ep h4 {
    margin-bottom: 0;
    color: #666666;
  }

  .review-rating {
    position: relative;
    justify-content: center !important;
    border-radius: 5px;
    width: 30px;
    height: 25px;
    border: 3px solid;
    border-color: #a5a5a5;
    font-size: 2px !important;
  }
  .review-rating.good {
    border-color: #00ab66;
  }
  .review-rating.bad {
    border-color: #ee0000;
  }
}
