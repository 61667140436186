.card-footer {
  display: flex;
  justify-content: space-between;
  padding: 2pt 4pt !important;
}
.interact-btns {
  display: flex;
  justify-content: space-between;
}
.interact-btn {
  margin-left: 4px !important;
}
.auto-margin.jsx-520943018 * {
  margin-right: 0 !important;
}

.post {
  display: flex;
  flex-grow: 1;
}

.anime-title {
  display: flex !important;
  justify-content: space-between;
  align-items: flex-start;
}
.show-rating {
  display: flex;
  justify-content: center !important;
  font-weight: 400 !important;
  border-radius: 5px;
  width: 32px;
  height: 30px;
  border: 1px solid;
  border-color: #a5a5a5;
}
.show-rating.bad {
  border-color: #ee0000;
}
.show-rating.good {
  border-color: #00ab66;
}

.content.jsx-891583295 {
  margin: 0 -16pt;
  padding: 0pt 16pt 8pt !important;
  position: relative;
  text-align: left;
}

.modal-btns {
  display: flex;
  justify-content: flex-start;
}

/* geist ui icon */
.like .jsx-3831253408.icon {
  color: rgb(238, 44, 44) !important;
}
