.navbar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-shadow: rgb(0 0 0 / 10%) 0px 0px 15px 0px;
  height: 70px;
  margin: auto;
}

.btn-container {
  display: flex;
  justify-content: space-between;
  margin-right: 10%;
  width: 80%;
}

.register-btn {
  margin-left: 0.5rem;
}

.logo {
  margin-bottom: -5%;
}

.right-btns {
  display: flex;
}

@media only screen and (max-width: 420px) {
  .btn-container {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}
