.container {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto;
  grid-gap: 30px;
}

.anime-card span {
  display: flex;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
}

.top-anime-cover-art {
  border-radius: 4px;
  object-fit: cover;

  aspect-ratio: 9 / 12;
  width: 100%;

  min-width: 136.656px;
  min-height: 167.859px;
}

.top-anime-cover-art:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
}

@media only screen and (max-width: 1240px) {
  .container {
    grid-template-columns: auto auto auto auto auto;
    grid-gap: 15px;
  }
}

@media only screen and (max-width: 900px) {
  .container {
    grid-template-columns: auto auto auto;
    grid-gap: 15px;
  }
}

@media only screen and (max-width: 550px) {
  .container {
    grid-template-columns: auto auto;
    grid-gap: 10px;
  }
}
