.info-content {
  padding-top: 20px;
  display: flex;
}

.cover-art {
  width: 25vw;
  border-radius: 5px;
  margin-top: 16px;
  box-shadow: 0 5px 10px rgb(0 0 0 / 6%);
  object-fit: cover;
}

.info-header {
  display: flex;
  margin-bottom: 30px;
}
.anime-data-container {
  margin-left: 20px;
  display: flex;
  align-items: flex-end;
}
.anime-data {
  margin-bottom: 10px;
}
.anime-data h1 {
  margin-bottom: 0px;
}
.anime-data h4 {
  margin-bottom: 0px;
}
.tag {
  margin-right: 5px;
}
.anime-data p {
  font-size: 10pt;
  color: #666666;
  margin-top: 0px;
}
.anime-data-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media only screen and (max-width: 1200px) {
  .cover-art {
    width: 300px;
    box-shadow: 0 30px 60px rgb(0 0 0 / 12%);
    border-radius: 5px;
    margin-top: 16px;
    object-fit: cover;
  }
  .info-header {
    display: flex;
  }
}

@media only screen and (max-width: 900px) {
  .anime-data p {
    font-size: 8pt;
    color: #666666;
    margin-top: 0px;
  }
}

@media only screen and (max-width: 780px) {
  .info-header {
    display: flex;
    flex-direction: column;
  }
  .cover-art {
    width: 60%;
    box-shadow: 0 5px 10px rgb(0 0 0 / 6%);
    border-radius: 5px;
    margin: auto;
    margin-top: 8px;
    margin-bottom: 20px;
    object-fit: cover;
  }
  .anime-data h1 {
    font-size: 2rem;
    margin-bottom: 6px;
  }
  .anime-data h4 {
    margin-bottom: 6px;
  }

  .tag {
    margin-right: 5px;
    font-size: 10px !important;
  }
}

@media only screen and (max-width: 420px) {
  .info-header {
    display: flex;
    flex-direction: column;
  }
}
